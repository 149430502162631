import React from 'react'
import { EnvelopeIcon, CalendarIcon } from '@heroicons/react/24/outline'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const Team = () => {
    const data = useStaticQuery(graphql`
        query People {
            directus {
                Team {
                First_Name
                Last_Name
                Credentials
                Bio
                Photo {
                    id
                    imageFile {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE)
                    }
                    }
                }
                Scheduling_Link
                Title
                Email
                }
            }
        }
        `)

    return (
        <section className="">
            {data.directus.Team.map((person, index) => (
                <section id={`${person.First_Name} ${person.Last_Name}`} key={person.Last_Name} className={"text-gray-600 body-font bg-gray-50 " + (index % 2 === 0 ? 'bg-blue-50' : '')}>
                    <div className={"container mx-auto flex px-5 py-10 lg:pb-0 lg:flex-row flex-col items-center " + (index % 2 === 0 ? "lg:flex-row-reverse" : "")}>
                        <div className="lg:max-w-lg lg:w-full w-1/2 mb-10 lg:mb-0 border-b-4 border-gray-200 lg:border-none">
                            <GatsbyImage image={person.Photo.imageFile.childImageSharp.gatsbyImageData} className={"object-cover object-center " + (index % 2 === 1 ? "transform scale-x-[-1]" : "")} alt={person.First_Name} />
                        </div>
                        <div className="lg:flex-grow lg:w-1/2 lg:pl-24 flex flex-col lg:items-start lg:text-left items-center text-center">
                            <small className="text-blue-500">{person.Title}</small>
                            <div className="lg:flex mb-4 lg:items-end">
                                <h1 className="title-font sm:text-5xl text-3xl font-medium text-gray-600">{person.First_Name} {person.Last_Name} </h1>
                                <div className="flex justify-center lg:justify-start">
                                    {person.Credentials &&
                                        <>
                                            {person.Credentials.map((cert, index) => (
                                                <p key={index} className='ml-2 text-gray-500'>
                                                    {cert === 'CFP' ? <>{cert}<sup>®</sup></> : cert}
                                                    {index < person.Credentials.length - 1 && ','}
                                                </p>
                                            ))}
                                        </>
                                    }
                                </div>
                            </div>
                            <p className="mb-8 leading-relaxed text-left">{person.Bio}</p>
                            <div className="flex flex-wrap md:justify-center lg:justify-start mt-0 space-y-0 lg:space-y-3 xl:space-y-0">
                                {person.Email &&
                                    <a className="px-6 py-3 text-lg leading-none rounded mr-4 inline-flex items-center hover:scale-105 active:scale-100 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-400 drop-shadow hover:text-white" href={"mailto:" + person.Email}><EnvelopeIcon className='h-4 w-4 mr-2' /> Email {person.First_Name}</a>
                                }
                                {person.Scheduling_Link &&
                                    <a className="px-6 py-3 text-lg leading-none rounded mr-4 inline-flex items-center hover:scale-105 active:scale-100 text-blue-500 bg-gray-50 hover:bg-gray-100 active:bg-white drop-shadow hover:text-blue-500" target='_blank' href={person.Scheduling_Link}><CalendarIcon className='h-4 w-4 mr-2' /> Meet with {person.First_Name}</a>
                                }
                                {/* <div className={` ${person.author ? false : 'hidden'}`}>
                                    <Button path={"/posts/" + person.fname + "-" + person.lname} style="link">Read {person.fname}'s Posts</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </section>
    )

}

export default Team
